import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('body').on('change', '.toggle-required', function(event) {
      // alert('loaded');
      if ($(".toggle-area").hasClass("active")) {
        $(".toggle-area").removeClass("active");
        $(".value-clean").val('');
      }
      else {
        $(".toggle-area").addClass('active');
      }
    });
  }
}
