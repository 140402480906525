import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('body').on('keyup', "#working_day_from_hrs", function(event) {
      let fromValue = $(this).val();

      if(fromValue != '' && fromValue != '0' && fromValue != '1' && fromValue != '2') {
        $('#working_day_from_min').focus();
      }
    });

    $('body').on('keyup', "#working_day_to_hrs", function(event) {
      let toValue = $(this).val();

      if(toValue != '' && toValue != '0' && toValue != '1' && toValue != '2') {
        $('#working_day_to_min').focus();
      }
    });

    $('body').on('keyup', "#working_day_from_min", function(event) {
      let fromValue = $(this).val();

      if(fromValue.length == 2) {
        $('#working_day_to_hrs').focus();
      }
    });
  }
}
