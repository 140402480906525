import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('body').on('change', "#working_day_release_starts_at", function(event) {
      // alert($(this).val())
      let date = new Date($(this).val())

      let overtime = $("#overtime-overview").data("overtimes")[date.getMonth() +1]
      let flextime_saldo = $("#flextime-saldo-overview").data("flextime-saldos")[date.getMonth() +1]

      if (overtime == undefined) {
        $("#overtimes-value").html("-")
      }
      else {
        $("#overtimes-value").html(overtime)
      }

      if (flextime_saldo == undefined) {
        $("#saldo-value").html("-")
      }
      else {
        $("#saldo-value").html(flextime_saldo)
      }
    });
  }
}
