import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('.datepicker').datepicker({
      language: "de",
      format: "dd.mm.yyyy",
      weekStart: 1
    });
  }
}
