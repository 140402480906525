import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // attachTableHandler = ->
    $('body').on('click', "tr[data-link]", function(event) {
      // alert("clicked")
      window.location = $(this).data("link")
    });
  }
}
